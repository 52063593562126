<template>
<div class="statistics">
  <div class="statistics-tit">
    <span></span>
    <p>试题数量统计</p>
  </div>
  <div class="cont mt10">
    <Row :gutter="10" >
      <Col :span="12">
        <div class="cont-user">
<!--          <p class="tit">学生统计：</p>-->
          <div class="cont-user-box">
            <div class="cont-user-box-item">
              <p class="cont-user-box-item-num">{{ info.dataCount }}</p>
              <p>总试题数量</p>
            </div>
            <!--                <div class="cont-user-box-item">-->
            <!--                  <p class="cont-user-box-item-num">86532</p>-->
            <!--                  <p>今日新增用户</p>-->
            <!--                </div>-->
            <div class="cont-user-box-item">
              <p class="cont-user-box-item-num">{{ info.labelKeypointCount }}</p>
              <p>知识点数量</p>
            </div>
            <div class="cont-user-box-item">
              <p class="cont-user-box-item-num">{{ info.labelKeywordCount }}</p>
              <p>关键词数量</p>
            </div>
          </div>
        </div>
      </Col>
      <Col :span="12">
        <div class="cont-user">
<!--          <p class="tit">组织统计：</p>-->
          <div class="cont-user-box">
            <div class="cont-user-box-item">
              <p class="cont-user-box-item-num">{{ info.dataVerifyCount }}</p>
              <p>试题待审核数量</p>
            </div>
            <div class="cont-user-box-item">
              <p class="cont-user-box-item-num">{{ info.dataCorrectionVerifyCount }}</p>
              <p>试题待纠错数量</p>
            </div>
            <div class="cont-user-box-item">
              <p class="cont-user-box-item-num">{{ info.dataSimilarVerifyCount }}</p>
              <p>相似题待审核数量</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
  <div class="cont mt10">
    <Row>
      <Col span="24">
        <div class="cont mt10">
          <div class="cont-tit">
            <div class="tit">试题星级评分：</div>
          </div>
          <div id="data-stat-by-star" class="num-chart"></div>
        </div>
      </Col>
    </Row>
  </div>
  <div class="cont mt10">
    <p class="tit">试题新增数量：</p>
    <div class="cont-search">
      <div>
        <span>题型</span>
        <el-select v-model="type" placeholder="请选择" clearable
                   @change="getStatByCreateTime" @clear="getStatByCreateTime">
          <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

      </div>
      <div>
        <span>难度</span>
        <el-select v-model="level" placeholder="请选择" clearable
                   @change="getStatByCreateTime" @clear="getStatByCreateTime">
          <el-option
              v-for="item in levelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>分类</span>
        <el-cascader :options="allCategoryList" v-model="categoryId" :props="optionProps" clearable @clear="getStatByCreateTime" @change="getStatByCreateTime"></el-cascader>
      </div>
      <div>
        <span>时间</span>
        <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            format="yyyy/MM/dd"
            @change="getStatByCreateTime"
            @clear="getStatByCreateTime"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="num-chart" id="data-add-num"></div>


  </div>
  <div class="cont mt10">
    <div class="cont-search">
      <div>
        <span>分类筛选：</span>
        <el-cascader :options="allCategoryList" v-model="workedCategoryId" :props="optionProps" clearable @change="getStatByDealTimeAndUser" @clear="getStatByDealTimeAndUser"></el-cascader>
      </div>
      <div>
        <span>时间筛选：</span>
        <el-date-picker
            v-model="workedDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            @change="getStatByDealTimeAndUser"
            @clear="getStatByDealTimeAndUser"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="verify-num">
      <div>
        <p class="tit">试题已审核数量：</p>
        <div class="num-chart" id="user-checked-data"></div>
      </div>
      <div>
        <p class="tit">试题已纠错数量：</p>
        <div class="num-chart" id="user-correct-data"></div>
      </div>
      <div>
        <p class="tit">相似题已审核数量：</p>
        <div class="num-chart" id="user-similar-data"></div>
      </div>
    </div>
  </div>
  <div class="cont mt10">
    <div class="cont-search">
      <div>
        <span>分类筛选：</span>
        <el-select v-model="firstCategory" placeholder="请选择" clearable
                   @change="changeFirstCategory"
        >
          <el-option
              v-for="item in firstCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <span style="width: 10px;display: inline-block"></span>
        <el-select v-model="twoCategory" placeholder="请选择" clearable
                   @change="changeSecondCategory" v-if="twoCategoryList.length"
        >
          <el-option
              v-for="item in twoCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>时间筛选：</span>
        <el-date-picker
            v-model="pendingDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            format="yyyy-MM-dd"
            @change="getStatByCategory"
            @clear="getStatByCategory"
        >
        </el-date-picker>
      </div>
    </div>
    <Row>
      <Col span="12">
        <div class="cont mt10" style="height: 550px;">
          <div>
            <p class="tit">相似题待处理：</p>
          </div>

          <div class="absorb-r-cont mt10" v-if="pendingObj.similarData && pendingObj.similarData.length" >
            <div class="absorb-r-cont-item" v-for="item in pendingObj.similarData" :key="item.id">
              <div class="absorb-r-cont-item-name">{{ item.category.title }}</div>
              <div class="absorb-r-cont-item-progress">
                <div class="progress-bj"><div class="progress-val" :style="{width:  (pendingObj.similarData[0].num!= 0 ? (item.num / pendingObj.similarData[0].num)*100 : 0) + '%'}"></div></div>
                <div class="absorb-r-cont-item-progress-nr">{{ item.num }}</div>
              </div>
            </div>
          </div>
          <div v-else class="no-data">
            <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
          </div>
        </div>
      </Col>
      <Col span="12">
        <div class="cont mt10" style="height: 550px;">
          <div>
            <p class="tit">纠错题待处理：</p>
          </div>

          <div class="absorb-r-cont mt10" v-if="pendingObj.correctionData && pendingObj.correctionData.length" >
            <div class="absorb-r-cont-item" v-for="item in pendingObj.correctionData" :key="item.id">
              <div class="absorb-r-cont-item-name">{{ item.category.title }}</div>
              <div class="absorb-r-cont-item-progress">
                <div class="progress-bj"><div class="progress-val" :style="{width:  (pendingObj.correctionData[0].num!= 0 ? (item.num / pendingObj.correctionData[0].num)*100 : 0) + '%'}"></div></div>
                <div class="absorb-r-cont-item-progress-nr">{{ item.num }}</div>
              </div>
            </div>
          </div>
          <div v-else class="no-data">
            <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
          </div>
        </div>
      </Col>
    </Row>
  </div>
  <Row :gutter="10">
    <Col span="12">
      <div class="cont mt10" style="height: 550px;">
        <div>
          <p class="tit">题型总题量统计：</p>
          <div class="cont-search">
            <div>
              <span>分类筛选：</span>
              <el-cascader :options="allCategoryList" v-model="typeCategoryId" :props="optionProps" clearable @change="getStatByType" @clear="getStatByType"></el-cascader>
            </div>
            <div>
              <span>难度筛选：</span>
              <el-select v-model="typeLevel" placeholder="请选择" clearable
                         @change="getStatByType" @clear="getStatByType"
              >
                <el-option
                    v-for="item in levelList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="absorb-r-cont mt10" v-if="typeAllNumList.length" >
          <div class="absorb-r-cont-item" v-for="item in typeAllNumList" :key="item.id">
            <div class="absorb-r-cont-item-name">{{ item.type_name }}</div>
            <div class="absorb-r-cont-item-progress">
              <div class="progress-bj"><div class="progress-val" :style="{width:  (typeAllNumList[0].count!= 0 ? (item.count / typeAllNumList[0].count)*100 : 0) + '%'}"></div></div>
              <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
        </div>
      </div>
    </Col>
    <Col span="12">
      <div class="cont mt10" style="height: 550px;">
        <p class="tit">各图谱刷题数量：</p>
        <div class="cont-search">
          <div>
            <span>分类筛选：</span>
            <el-cascader :options="allCategoryList" v-model="mapCategoryId" :props="optionProps" clearable @change="getStatMapTrain" @clear="getStatMapTrain"></el-cascader>
          </div>
          <div>
            <span>时间筛选：</span>
            <el-date-picker
                v-model="typeDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                format="yyyy-MM-dd"
                @change="getStatMapTrain"
                @clear="getStatMapTrain"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="absorb-r-cont mt10" v-if="mapNumList.length" >
          <div class="absorb-r-cont-item" v-for="item in mapNumList" :key="item.id">
            <div class="absorb-r-cont-item-name">{{ item.map_name }}</div>
            <div class="absorb-r-cont-item-progress">
              <div class="progress-bj"><div class="progress-val" :style="{width:  (mapNumList[0].count!= 0 ? (item.count / mapNumList[0].count)*100 : 0) + '%'}"></div></div>
              <div class="absorb-r-cont-item-progress-nr">{{ item.count }}</div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">
          <img src="../../assets/images/new_icon/kong.png" width="355" height="190" />
        </div>
      </div>
    </Col>
  </Row>
  <div class="statistics-tit mt20">
    <span></span>
    <p>刷题统计</p>
  </div>
  <div class="cont mt10">
    <div class="cont-tit">
      <p class="tit">刷题频次数量统计：</p>
      <div class="cont-search">
        <div>
          <span>分类筛选：</span>
          <el-cascader :options="allCategoryList" v-model="frequencyCategoryId" :props="optionProps" clearable
          @change="getFrequencyData"  @clear="getFrequencyData"></el-cascader>
        </div>
        <div>
          <span>时间筛选：</span>
          <!-- <el-date-picker
              v-model="frequencyDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              format="yyyy-MM-dd"
              @change="getFrequencyData"
              @clear="getFrequencyData"
          >
          </el-date-picker> -->
          <el-date-picker
              v-model="frequencyDate"
              type="month"
              placeholder="选择月"
              @change="getFrequencyData"
              @clear="getFrequencyData"
          >
          </el-date-picker>
        </div>

      </div>
    </div>
    <div class="num-chart" id="frequency-data"></div>
  </div>
  <div class="cont mt10">
    <div class="cont-tit">
      <p class="tit">刷题类目统计：</p>
      <div class="cont-search">
        <div>
          <span>分类筛选：</span>
          <el-cascader :options="allCategoryList" v-model="categoryTrainCategoryId" :props="optionProps" clearable @change="searchCategoryTrain" @clear="searchCategoryTrain"></el-cascader>
        </div>
        <div>
          <span>时间筛选：</span>
<!--          <el-date-picker-->
<!--              v-model="categoryTrainDate"-->
<!--              type="month"-->
<!--              range-separator="至"-->
<!--              start-placeholder="开始日期"-->
<!--              end-placeholder="结束日期"-->
<!--              value-format="yyyy/MM/dd"-->
<!--              @change="searchCategoryTrain"-->
<!--              @clear="searchCategoryTrain"-->
<!--              >-->
<!--          </el-date-picker>-->


          <el-date-picker
              v-model="categoryTrainDate"
              type="month"
              placeholder="选择月"
              @change="searchCategoryTrain"
              @clear="searchCategoryTrain"
          >
          </el-date-picker>
        </div>
        <Button type="primary" @click="exportCategoryTrain">导出</Button>
      </div>
    </div>
    <div class="mt10">
      <Table :columns="categoryStatColumns" :data="categoryStatData"></Table>
    </div>

    <div class="page mt10">
      <MyPage :total="categoryStatTotal" :current="categoryStatPage" @changePage="changeCategoryStatPage" @changePageSize="changeCategoryStatPageSize"></MyPage>
    </div>
  </div>
<!--  <div class="cont mt10">-->
<!--    <div class="cont-search">-->
<!--      <div>-->
<!--        <span>分类筛选：</span>-->
<!--        <el-cascader :options="allCategoryList" v-model="categoryId" :props="optionProps" clearable></el-cascader>-->
<!--      </div>-->
<!--      <div>-->
<!--        <span>时间筛选：</span>-->
<!--        <DatePicker :value="date" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 200px"></DatePicker>-->
<!--      </div>-->
<!--      <Button type="primary">导出</Button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div>-->
<!--        <p class="tit">刷题难度统计</p>-->
<!--        <div class="mt10">-->
<!--          <Table :columns="levelColumns" :data="levelData"></Table>-->
<!--        </div>-->

<!--        <div class="page mt10">-->
<!--          <MyPage :total="levelTotal" :current="levelPage" @changePage="changeLevelPage" @changePageSize="changeLevelPageSize"></MyPage>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <p class="tit">刷题题型统计</p>-->
<!--        <div class="mt10">-->
<!--          <Table :columns="typeColumns" :data="typeData"></Table>-->
<!--        </div>-->

<!--        <div class="page mt10">-->
<!--          <MyPage :total="typeTotal" :current="typePage" @changePage="changeTypePage" @changePageSize="changeTypePageSize"></MyPage>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--  </div>-->
<!--  <div class="statistics-tit mt20">-->
<!--    <span></span>-->
<!--    <p>检索关键词排行（Top30）</p>-->
<!--  </div>-->
<!--  <div class="cont mt10">-->
<!--    <div class="mt10">-->
<!--      <Table :columns="keywordColumns" :data="keywordData"></Table>-->
<!--    </div>-->

<!--    <div class="page mt10">-->
<!--      <MyPage :total="keywordTotal" :current="keywordPage" @changePage="changeKeywordPage" @changePageSize="changeKeywordPageSize"></MyPage>-->
<!--    </div>-->
<!--  </div>-->
  <div class="statistics-tit mt20">
    <span></span>
    <p>更新日志</p>
  </div>
  <div class="cont mt10">
    <div class="mt10">
      <Table :columns="updateColumns" :data="updateData"></Table>
    </div>

    <div class="page mt10">
      <MyPage :total="updateTotal" :current="updatePage" @changePage="changeUpdatePage" @changePageSize="changeUpdatePageSize"></MyPage>
    </div>
  </div>
  <div class="statistics-tit mt20">
    <span></span>
    <p>星级统计</p>
  </div>
  <div class="cont mt10">
    <div class="search">
      <div class="search-item">
        <div class="name">
          一级分类:
        </div>
        <div class="search-cont">
          <div class="values">
            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="search-item" v-if="secondCategoryList.length">
        <div class="name">
          二级分类:
        </div>
        <div class="search-cont">
          <div class="values" >
            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="star" style="width: 100%;height:500px"></div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "homeContentManager.vue",
  data(){
    return {
      info:{},
      type:'',
      typeList:[],
      level:'',
      levelList:[],
      categoryId:[],
      allCategoryList:[],
      optionProps: {
        value: "child_id",
        label: "name",
        children: "children",
      },
      date:[],
      categoryStatColumns:[
        {
          title: '一级类目',
          key: 'cat_name_1'
        },
        {
          title: '二级类目',
          key: 'cat_name_2'
        },
        {
          title: '三级类目',
          key: 'cat_name_3'
        },
        {
          title: '总题数',
          key: 'data_count'
        },
        {
          title: '刷题数',
          key: 'train_count'
        },
        // {
        //   title: '未刷过题数',
        //   key: 'ID'
        // },
        {
          title: '刷题人数',
          key: 'user_count'
        },
        {
          title: '正确率',
          key: 'accuracy'
        },
      ],
      categoryStatData:[],
      categoryStatTotal:0,
      categoryStatPage:1,
      categoryStatPageSize:10,
      levelColumns:[
        {
          title: '难度',
          key: 'ID'
        },
        {
          title: '总题数',
          key: 'ID'
        },
        {
          title: '刷题数',
          key: 'ID'
        },
        {
          title: '未刷过题数',
          key: 'ID'
        },
        {
          title: '刷题人数',
          key: 'ID'
        },
        {
          title: '正确率',
          key: 'ID'
        },
      ],
      levelData:[],
      levelTotal:0,
      levelPage:1,
      levelPageSize:10,
      typeColumns:[
        {
          title: '题型',
          key: 'ID'
        },
        {
          title: '总题数',
          key: 'ID'
        },
        {
          title: '刷题数',
          key: 'ID'
        },
        {
          title: '未刷过题数',
          key: 'ID'
        },
        {
          title: '刷题人数',
          key: 'ID'
        },
        {
          title: '正确率',
          key: 'ID'
        },
      ],
      typeData:[],
      typeTotal:0,
      typePage:1,
      typePageSize:10,
      keywordColumns:[
        {
          title: '排行',
          key: 'ID'
        },
        {
          title: '关键词',
          key: 'ID'
        },
        {
          title: '检索次数',
          key: 'ID'
        },
      ],
      keywordData:[],
      keywordTotal:0,
      keywordPage:1,
      keywordPageSize:10,
      updateColumns:[
        {
          title: '更新题库',
          render:(h,params)=>{
            let arr = [];
            if(params.row.cat_name_1) {
              arr.push(params.row.cat_name_1)
            }
            if(params.row.cat_name_2) {
              arr.push(params.row.cat_name_2)
            }
            if(params.row.cat_name_3) {
              arr.push(params.row.cat_name_3)
            }
            return h('span', arr.join('-'));
          }
        },
        {
          title: '更新数量',
          key: 'count'
        },
        {
          title: '操作',
          key: 'action_name'
        },
        {
          title: '时间',
          key: 'date'
        },
      ],
      updateData:[],
      updateTotal:0,
      updatePage:1,
      updatePageSize:10,
      curCategoryId:'',
      cursecondCategoryId:'',
      categoryList:[],
      secondCategoryList:[],
      pageStatus:true,
      workedCategoryId:'',
      workedDate:[],
      typeCategoryId:'',
      typeDate:[],
      typeLevel:'',
      typeAllNumList:[],
      mapNumList:[],
      frequencyCategoryId:'',
      frequencyDate:'',
      frequencyDateMonth:[],
      categoryTrainCategoryId:'',
      categoryTrainDate:'',
      categoryTrainDateMonth:[],
      dataAddNum:[],
      option: {
        title: {
          text: "暂无数据",
          x: "center",
          y: "center",
          textStyle: {
            color: "#333333",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
      },
      mapCategoryId:'',
      firstCategory:'',
      twoCategory:'',
      firstCategoryList:[],
      twoCategoryList:[],
      pendingDate:[],
      pendingObj:{},
    }
  },
  beforeDestroy(){
    this.pageStatus = false;
  },
  created(){

    // this.date = [maxDate,minDate];
    const date = new Date();
    let maxDate = date.setTime(date.getTime() - 3600 * 1000 * 24);

    maxDate = util.timeFormatter(
        new Date(+maxDate), 'yyyy/MM/dd'
    )
    let minDate = date.setTime(date.getTime() - 3600 * 1000 * 24 *30);
    minDate = util.timeFormatter(
        new Date(+minDate), 'yyyy/MM/dd'
    );
    this.date = [minDate,maxDate];
    this.workedDate = [minDate,maxDate];
    this.typeDate = [minDate,maxDate];
    //this.frequencyDate = [minDate,maxDate];

    const date1 = new Date();
    this.categoryTrainDate = new Date(date1.getFullYear(), date1.getMonth(), 1);
    this.categoryTrainDateMonth = util.getMonthLastDayAndFirstDay(date1);

    this.frequencyDate = new Date(date1.getFullYear(), date1.getMonth(), 1);
    this.frequencyDateMonth = util.getMonthLastDayAndFirstDay(date1);


  },
  mounted(){

    this.getInfo();
    this.getCategoryTree();
    this.getStatByCreateTime();
    this.getCategoryList();
    this.getStatByDealTimeAndUser();
    this.getTypeData();
    this.getFrequencyData();
    this.getStatCategoryTrain();
    this.getRecordByAction();

    this.getDataStarData();

    this.getFirstCategoryList();
    this.getStatByCategory();
  },
  methods:{
    getInfo(){
      this.api.home.indexContentManagerNew().then((res)=>{
        this.info = res;
        this.typeList = [];
        for(let name in this.info.dataTypes){
          this.typeList.push({
            label:this.info.dataTypes[name],
            value:name
          })
        }
        this.levelList = [];
        for(let name in this.info.dataLevels){
          this.levelList.push({
            label:this.info.dataLevels[name],
            value:name
          })
        }
      })
    },
    getCategoryTree(){
      let params = {
        site_id:'-1'
      }
      this.api.home.categoryTree(params).then((res)=>{
        this.allCategoryList = res.tree;
      })
    },
    getStatByCreateTime(){
      // console.log(this.categoryId,'this.categoryId')
      let params = {
        level:this.level,
        type:this.type,
        category_id:this.categoryId[this.categoryId.length - 1],
      }
      if(this.date){
        params.start_time = new Date(this.date[0]).getTime()/1000;
        params.end_time = new Date(this.date[1]).getTime()/1000;
      }
      this.api.home.statByCreateTime(params).then((res)=>{
        this.dataAddNum = res.list;
        this.setAddNumOption(res.list)
      })
    },
    setAddNumOption(data){
      let chartDom = document.getElementById('data-add-num');

      let myChart = this.$echarts.init(chartDom);

      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.date);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '新增',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };
      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }


      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    getStatByDealTimeAndUser(){
      let params = {
        category_id:this.workedCategoryId[this.workedCategoryId.length - 1],
      }
      if(this.workedDate){
        params.start_time = new Date(this.workedDate[0]).getTime()/1000;
        params.end_time = new Date(this.workedDate[1]).getTime()/1000;
      }
      this.api.home.statByDealTimeAndUser(params).then((res)=>{
        this.setDealTimeOption('user-checked-data',res.userCheckedData);
        this.setDealTimeOption('user-correct-data',res.userCorrectData);
        this.setDealTimeOption('user-similar-data',res.userSimilarData);
      })
    },
    setDealTimeOption(name,data){
      var chartDom = document.getElementById(name);
      var myChart = this.$echarts.init(chartDom);
      var option;

      let series = data.value.map((item)=>{
        let data = Object.values(item.dateCount);
        let obj = {
          name: item.user_name,
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          data: data
        }
        return obj
      })

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show:true
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: Object.values(data.dates)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: series
      };

      if(data.value.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
    },
    getTypeData(){
      this.getStatByType();
      this.getStatMapTrain();
    },
    getStatByType(){
      let params = {
        category_id:this.typeCategoryId[this.typeCategoryId.length - 1],
        level:this.typeLevel
      }

      // if(this.typeDate){
      //   params.start_time = new Date(this.typeDate[0]).getTime()/1000;
      //   params.end_time = new Date(this.typeDate[1]).getTime()/1000;
      // }
      this.api.home.statByType(params).then((res)=>{
        this.typeAllNumList = res.list;
      })
    },
    getStatMapTrain(){
      let params = {
        category_id:this.mapCategoryId[this.mapCategoryId.length - 1],
      }
      if(this.typeDate){
        params.start_time = new Date(this.typeDate[0]).getTime()/1000;
        params.end_time = new Date(this.typeDate[1]).getTime()/1000;
      }

      this.api.home.statMapTrain(params).then((res)=>{
        this.mapNumList = res.list;
      })
    },
    getFrequencyData(){
      let params = {
        category_id:this.frequencyCategoryId[this.frequencyCategoryId.length - 1],
      }
      this.frequencyDateMonth = util.getMonthLastDayAndFirstDay(this.frequencyDate);

      // if(this.frequencyDate){
      //   params.start_time = new Date(this.frequencyDate[0]).getTime()/1000;
      //   params.end_time = new Date(this.frequencyDate[1]).getTime()/1000;
      // }
      if(this.frequencyDate){
        params.start_time = new Date(this.frequencyDateMonth.firstDay).getTime()/1000;
        params.end_time = new Date(this.frequencyDateMonth.lastDay).getTime()/1000;
      }
      this.api.home.statTrainFrequency(params).then((res)=>{
        this.setFrequencyDataOption(res.list);
      })
    },
    setFrequencyDataOption(data){
      let chartDom = document.getElementById('frequency-data');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.level);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '题数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    searchCategoryTrain(){
      this.categoryStatPage = 1;
      this.categoryTrainDateMonth = util.getMonthLastDayAndFirstDay(this.categoryTrainDate);
      // console.log(this.categoryTrainDateMonth,'this.categoryTrainDateMonth')
      this.getStatCategoryTrain();
    },
    getStatCategoryTrain(){
      let params = {
        category_id:this.categoryTrainCategoryId[this.categoryTrainCategoryId.length - 1],
        page:this.categoryStatPage,
        pageSize:this.categoryStatPageSize
      };

      if(this.categoryTrainDate){
        params.start_time = new Date(this.categoryTrainDateMonth.firstDay).getTime()/1000;
        params.end_time = new Date(this.categoryTrainDateMonth.lastDay).getTime()/1000;
      }
      this.api.home.statCategoryTrain(params).then((res)=>{
        this.categoryStatData = res.list;
        this.categoryStatTotal = +res.count;
      })
    },
    exportCategoryTrain(){
      let params = {
        category_id:this.categoryTrainCategoryId[this.categoryTrainCategoryId.length - 1],
        page:this.categoryStatPage,
        pageSize:this.categoryStatPageSize
      };

      if(this.categoryTrainDate){
        params.start_time = new Date(this.categoryTrainDateMonth.firstDay).getTime()/1000;
        params.end_time = new Date(this.categoryTrainDateMonth.lastDay).getTime()/1000;
      }

      this.api.home.statCategoryTrainExport(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res)
        })
      })
    },





    changeCategoryStatPage(page){
      this.categoryStatPage = page;
      this.getStatCategoryTrain();
    },
    changeCategoryStatPageSize(pageSize){
      this.categoryStatPage = 1;
      this.categoryStatPageSize = pageSize;
      this.getStatCategoryTrain();
    },
    // changeLevelPage(page){
    //   this.levelPage = page;
    //
    // },
    // changeLevelPageSize(pageSize){
    //   this.levelPage = 1;
    //   this.levelPageSize = pageSize;
    // },
    // changeTypePage(page){
    //   this.typePage = page;
    //
    // },
    // changeTypePageSize(pageSize){
    //   this.typePage = 1;
    //   this.typePageSize = pageSize;
    // },
    // changeKeywordPage(page){
    //   this.keywordPage = page;
    //
    // },
    // changeKeywordPageSize(pageSize){
    //   this.keywordPage = 1;
    //   this.keywordPageSize = pageSize;
    // },
    changeUpdatePage(page){
      this.updatePage = page;
      this.getRecordByAction();
    },
    changeUpdatePageSize(pageSize){
      this.updatePage = 1;
      this.updatePageSize = pageSize;
      this.getRecordByAction();
    },
    getRecordByAction(){
      let params = {
        page:this.updatePage,
        pageSize:this.updatePageSize
      };
      this.api.home.statRecordByAction(params).then((res)=>{
        this.updateData = res.list;
        this.updateTotal = +res.count;
      })
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondCategoryList();
      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        // console.log(this.secondCategoryList,'this.secondCategoryList')

        this.cursecondCategoryId = this.secondCategoryList[0].id;
        this.getStarData();

      })
    },
    changeSearch(data,name){
      this[name] = data.id;
      if(name == 'curCategoryId'){
        this.getSecondCategoryList();
      }
      if(name == 'cursecondCategoryId'){
        this.getStarData();
      }
    },
    getStarData(){
      let params = {
        category_id:this.cursecondCategoryId
      }
      this.api.dataset.datasetCategoryProfiles(params).then((res)=>{
        let seriesData = [];
        let legendData = [];
        this.categoryStatList = res.categoryStatList;
        if(res.categoryStatList.length) {
          let date = res.categoryStatList[0].map((item)=>{
            return item.date;
          });
          res.categoryStatList.forEach((item)=>{
            let data = [];
            item.forEach((sItem)=>{
              data.push(sItem.star_average)
            })
            seriesData.push({
              name: item[0].category.name,
              type: 'line',
              // stack: 'Total',
              data:data,
            });
            legendData.push(item[0].category.name)
          })
          this.$nextTick(()=>{
            if(this.pageStatus){
              this.setChart(date,seriesData,legendData);
            }
          })
        }else{
          this.$nextTick(()=>{
            if(this.pageStatus){
              this.setChart('',[],[]);
            }
          })
        }


      })
    },
    setChart(date,seriesData,legendData){
      let chartDom = document.getElementById('star');
      let myChart = this.$echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params, ticket, callback)=> {
            var htmlStr = '';
            for(var i=0;i<params.length;i++){
              var param = params[i];
              var xName = param.name;//x轴的名称
              var seriesName = param.seriesName;//图例名称
              var value = param.value;//y轴值
              var color = param.color;//图例颜色

              if(i===0){
                htmlStr += xName + '<br/>';//x轴的名称
              }
              htmlStr +='<div>';

              // 具体显示的数据【字段名称：seriesName，值：value】
              // 这里判断一下name，如果是我们需要特殊处理的，就处理
              htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
              htmlStr += seriesName + '：' + value + '/' + this.categoryStatList[param.seriesIndex][param.dataIndex].total_data_count;

              htmlStr += '</div>';
            }
            return htmlStr;
          }
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: date
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      };

      if(date.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },

    getDataStarData(){
      let params = {
        site_id:-1,
        user_id:-1
      }
      this.api.dataset.dataStatByStar(params).then((res)=>{
              this.setChart1(res.list);
      })
    },
    setChart1(data){
      // console.log('setChart1')

      let chartDom = document.getElementById('data-stat-by-star');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.level);
        counts.push(res.count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '题数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    getFirstCategoryList(name){
      let data = {
        site_id:-1,
        user_id:-1,
        type:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{
        this.firstCategoryList = [
          ...res.categoryFirstList
        ]
        this.twoCategoryList = [];
      })
    },
    getTwoCategoryList(name){
      let data = {
        site_id:-1,
        user_id:-1,
        type:-1,
        category_id:this.firstCategory,
      };
      this.api.dataset.categoryForm(data).then((res)=>{

        this.twoCategoryList =[
          ...res.categorySecondList
        ]


      })
    },
    changeFirstCategory(){
      if(this.firstCategory){
        this.getTwoCategoryList();
      }else{
        this.twoCategoryList = [];

      }
      this.twoCategory = '';
      this.getStatByCategory();
    },
    changeSecondCategory(){
      this.getStatByCategory();
    },
    getStatByCategory(){
      let params = {};
      if(this.pendingDate){
        params.start_time = new Date(this.pendingDate[0]).getTime()/1000;
        params.end_time = new Date(this.pendingDate[1]).getTime()/1000;
      }
      if(!this.twoCategory){
        if(this.firstCategory){
          params.category_id = this.firstCategory;
        }
      }else{
        params.category_id = this.twoCategory;
      }
      this.api.dataset.datasetStatByCategory(params).then((res)=>{
        this.pendingObj = res;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.statistics{
  margin: 20px;
  //padding: 20px;
  font-size: 14px;
  color: #333333;
  //background: #ffffff;
  .statistics-tit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
      margin-right: 8px;
      display: inline-block;
      width: 6px;
      height: 18px;
      background: linear-gradient(93deg, #4A79FF, #0042FF);
      border-radius: 4px;
    }
    >p{
      font-size: 18px;
      font-weight: bold;
    }
  }

  .cont{
    background: #FFFFFF;
    padding: 20px;
    .cont-tit{
      //font-weight: bold;
      //font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cont-tit-r{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right:20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .cont-search{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >span{
          margin-right: 5px;
        }
      }
    }
  }
  .tit{
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: bold;
  }
  .cont-user{
    //margin-right: 20px;
    padding: 30px;
    background: #F6F7FA;
    border-radius: 6px;
    .cont-user-box{
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;

      .cont-user-box-item-num{
        color: #4A79FF;
        font-size: 20px;
        font-weight: bold;
      }
      .cont-user-box-item-top{
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        >p:first-child{
          margin-right: 10px;
        }
      }
    }
  }

  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .search-cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .num-chart{
    width: 100%;
    height: 400px;
  }
  .site-area{
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: 760px;
    .site-area-l{
      padding: 20px;
      flex: 1;
      width: 0;
      height: 700px;
    }
    .site-area-r{
      width: 500px;
      padding: 20px;
      border-left: 1px solid #EEEEEE;
      height: 760px;
    }
  }
  .page{
    text-align: right;
  }
  .attestation-cont{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-right: 20px;
      flex: 1;
      padding: 20px;
      height: 500px;
      border: 1px solid #EEEEEE;
    }
    >div:last-child{
      margin-right: 0;
    }
  }
  .absorb-r-cont{
    //padding: 0 20px 20px 20px;
    height: 410px;
    overflow-y: auto;
    overflow-y: auto;

    .absorb-r-cont-item{
      margin-bottom: 16px;
      .absorb-r-cont-item-name{
        margin-bottom: 5px;
      }
      .absorb-r-cont-item-progress{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .absorb-r-cont-item-progress-nr{
          margin-left: 16px;
        }
      }
    }
  }
  .progress-bj{
    flex: 1;
    height: 10px;
    background: #EEEEEE;
    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .verify-num{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    >div{
      padding: 20px;
      margin-right: 20px;
      height: 500px;
      border: 1px solid #EEEEEE;
      flex: 1;
    }
    >div:last-child{
      margin-right: 0;
    }
  }
  .no-data{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style>
.el-cascader-menu{
  height: 200px !important;
}
</style>
